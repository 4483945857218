import * as React from "react";

import { Helmet } from "react-helmet";

import PageLayout from "../components/PageLayout";

import { moduleStyle, moduleInner } from './contact.module.scss';

import { Col, Container, Row, Form, Button, Alert } from 'react-bootstrap';

import axios from 'axios';

// Taken from https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
const EMAIL_MATCHER = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function isEmailValid(email) {
    return String(email).toLowerCase().match(EMAIL_MATCHER);
}

const preparedMessages = {
    demo: {
        subject: "Requesting a demo",
        message: "Hello!\n\nMy name is <Your Name>, from <Your Institution>.  I'm interested in using the TC-X1 conductivity sensor, and would like to schedule a time to discuss a demo to see if it would fit our use case."
    },
    order: {
        subject: "TC-X1 Order",
        message: "Hello!\n\nMy name is <Your Name> from <Your Institution>, and I'd like to purchase the TC-X1 conductivity sensor."
    },
    help: {
        subject: "Requesting Help",
        message: "Hello!\n\nMy name is <Your Name> from <Your Institution>, and I need more help using your products.  Please let me know when we can schedule time to talk more."
    },
    privacy: {
        subject: "Privacy Concern",
        message: "Hello!\n\nMy name is <Your Name> from <Your Institution>, and I have a concern about my data on this website."
    }
};

const ContactPage = () => {
    const [emailAddress, setEmailAddress] = React.useState('');
    const [emailValid, setEmailValid] = React.useState(true);
    const [subject, setSubject] = React.useState('');
    const [subjectValid, setSubjectValid] = React.useState(true);
    const [messageContent, setMessageContent] = React.useState('');
    const [messageValid, setMessageValid] = React.useState(true);

    const [isLoading, setIsLoading] = React.useState(false);
    const [completionMessage, setCompletionMessage] = React.useState('');
    const [messageType, setMessageType] = React.useState(null);
    const [canSend, setCanSend] = React.useState(true);

    const handleSubjectChange = (event) => {
        setSubjectValid(event.target.value !== '');
        setSubject(event.target.value);
    };

    const handleAddressChange = (event) => {
        setEmailValid(event.target.value !== '');
        setEmailAddress(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessageValid(event.target.value !== '');
        setMessageContent(event.target.value);
    };

    const submit = async (event) => {
        let foundError = false;
        // Validate fields
        if(subject === '' || emailAddress === '' || messageContent === '') {
            if(subject === '') setSubjectValid(false);
            if(emailAddress === '') setEmailValid(false);
            if(messageContent === '') setMessageValid(false);

            setCompletionMessage('Please fill out all email fields so we can get back to you.')
            setMessageType('warning')
            foundError = true;
        }
        if(!isEmailValid(emailAddress)) {
            setEmailValid(false);
            setCompletionMessage('Please enter a valid email adress (e.g. you123@place.com).')
            setMessageType('warning')
            foundError = true;
        }

        if(foundError) {
            event.preventDefault();
            return;
        }

        // Mark loading
        setIsLoading(true)

        try {
            let response = await axios.post('https://ypim57pp8c.execute-api.us-west-1.amazonaws.com/contact', {
                subject,
                emailAddress,
                messageContent
            });

            if(response.status === 200) {
                setCompletionMessage("Thanks for sending a message!  We'll be in touch soon.");
                setMessageType('success');
                // Prevent sending further messages
                setCanSend(false);
            } else {
                setCompletionMessage(`We couldn't send your message. ${response.status === 400 ? response.data.message : "Try again later."}`);
                setMessageType('warning');
            }
        } catch (e) {
            // console.log("Caught an error attempting to send message: ", e);
            setCompletionMessage("Something unexpected happened.  Please check your internet connection, or try again later.");
            setMessageType('danger');
        }

        // Return to normal
        setIsLoading(false)

        event.preventDefault();
    };

    React.useEffect(async => {
        const pageVariant = new URLSearchParams(document.location.search).get("template");
        if(pageVariant && pageVariant in preparedMessages) {
            setSubject(preparedMessages[pageVariant].subject);
            setMessageContent(preparedMessages[pageVariant].message);
        }
    }, []);

    return (
        <PageLayout>
            <Helmet htmlAttributes={{
                lang: 'en',
            }}>
                <title>Tayos Corp - Contact Form</title>
            </Helmet>
            <Container className={moduleStyle}>
                <Row>
                    <Col md={4}>
                        <h1>Contact Us</h1>
                        <p>
                            For inquiries about the product, research assistance, or general technical consulting, submit a contact request here.  We'll get back to you
                            soon!
                        </p>
                    </Col>
                    <Col md={8}>
                        <div className={moduleInner}>
                            <Form action="" onSubmit={(event) => { event.preventDefault(); return false; }}>
                            <Form.Group className="mb-3" controlId="emailAddress">
                                <Form.Label id="email-label">Email address</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    aria-labelledby="email-label"
                                    placeholder="your_email@email.com" 
                                    value={emailAddress} 
                                    onChange={handleAddressChange} 
                                    isInvalid={!emailValid}
                                    disabled={isLoading} />
                                <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="subject">
                                <Form.Label id="email-subject">Subject</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    aria-labelledby="email-subject"
                                    placeholder="Reaching out" 
                                    value={subject} 
                                    onChange={handleSubjectChange} 
                                    isInvalid={!subjectValid}
                                    disabled={isLoading} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="messageContent">
                                <Form.Label id="email-content">What do you want to say?</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    aria-labelledby="email-content"
                                    rows={4} 
                                    value={messageContent} 
                                    onChange={handleMessageChange} 
                                    isInvalid={!messageValid}
                                    disabled={isLoading} />
                            </Form.Group>

                            { messageType && <Alert variant={messageType}>{completionMessage}</Alert> }

                            <Button 
                                variant="primary" 
                                type="submit" 
                                disabled={isLoading || !canSend}
                                onClick={submit}>
                                    {canSend ? "Send" : "Sent!"}
                            </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PageLayout>
    )
}

export default ContactPage